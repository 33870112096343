$dotSize: 0.55em;

$dot-active-color: rgb(54, 54, 54);
$dot-inactive-color: rgb(153, 153, 153);

.stepper {

    .stepper-dot {
        width: $dotSize;
    }

    .stepper-dot-active {
        circle {
            fill: $dot-active-color;
        }
    }

    .stepper-dot-inactive {
        circle {
            fill: $dot-inactive-color;
        }
    }

    @for $i from 1 through 10 {
        &.stepper-#{$i} {
            width: (2 * $i - 1) *  $dotSize;
        }
    }
}