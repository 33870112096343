@use '../App' as *;

.level-result {

    .clap-icon-container {
        max-width: 9vw;
        width: 100%;
        max-height: 4.4em;

        .svg-icon {
            height: 100%;
        }
    }

    .level-result-text {

        &.long-text {
            .correct-count {
                font-size: 1.4em;
            }

            .correct-answers {
                font-size: 1.05em;
            }

            .result-main-text {
                font-size: 0.87em;
            }
        }

        .correct-count {
            font-size: 1.8em;
            line-height: 1.0;
            font-weight: bold;
        }

        .correct-answers {
            font-size: 1.35em;
            line-height: 1.0;
            font-weight: bold;
        }
    }
}