@use '../App' as *;

.intro-background {
    position: absolute;

    width: 100vw;
    height: 100vh;

    overflow: hidden;

    img {
        width: 100%;
        height: 100%;

        object-fit: cover;
    }

    .domtom-background {
        background-color: $theme-light-orange;

        width: 100%;
        height: 100%;
    }
}


.intro-foreground {
    z-index: 1;
}

.intro-text {

    div {
        text-align: center;
        width: 100%;
    }
}