@use '../App' as *;

$icon-stroke: 6;
$icon-thick-stroke: 40;

.svg-icon-shadow {
    box-shadow: 1px 2px 3px $icon-shadow;
}

.svg-icon {

    border-radius: 100%;
    
    svg {

        height: 100%;

        path {
            fill: white;
        }

        path.svg-outline {
            stroke-width: $icon-stroke;
            stroke-linecap:round;
            stroke-linejoin:round;
        }

        g.svg-thick-strokes path {
            stroke-width: $icon-thick-stroke;
            stroke-linecap:round;
            stroke-linejoin:round;
        }
    }

    &.svg-outline-green {
        svg path {
            stroke: $theme-light-green;
        }
    }

    &.svg-outline-yellow {
        svg path {
            stroke: $theme-yellow;
        }
    }
}

.svg-icon-green svg {
    circle, path.svg-outline {
        fill: $theme-light-green;
    }

    g.svg-thick-strokes path {
        fill: $theme-light-green;
        stroke: $theme-light-green;
    }
}

.svg-icon-darkgreen svg {
    circle, path.svg-outline {
        fill: $theme-dark-green;
    }

    g.svg-thick-strokes path {
        fill: $theme-dark-green;
        stroke: $theme-dark-green;
    }
}

.svg-icon-lightgreen svg {
    circle, path.svg-outline {
        fill: $theme-lighter-green;
    }

    g.svg-thick-strokes path {
        fill: $theme-lighter-green;
        stroke: $theme-lighter-green;
    }
}

.svg-icon-yellow svg {
    circle, path.svg-outline {
        fill: $theme-yellow;
    }

    g.svg-thick-strokes path {
        fill: $theme-yellow;
        stroke: $theme-yellow;
    }
}

.outlined-icon {
    path {
        stroke: white;
        stroke-width: $icon-stroke;
        fill: none !important;

        stroke-miterlimit: 10;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
}