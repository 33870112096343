$theme-dark-green: #123040;
$theme-light-green: #008c78;
$theme-lighter-green: #66BAAD;
$theme-light-orange: #FFA17A;
$theme-yellow: #ffb301;
$theme-white: white;
$theme-black: black;

$color-hot: #fa6321;
$color-comfort: #008c78;
$color-cold: #005eb0;

$main-font: "Futura bold";
$secondary-font: "Futura medium";
$tertiary-font: "Futura light";

$main-font-size: 1.35em;

$white-text: $theme-white;

$icon-shadow: rgba(0, 0, 0, 0.5);

$background-color: $theme-white;
$main-text-color: $theme-dark-green;
$secondary-text-color: $theme-white;

$btnMaxSize: 140px;

@font-face {
    font-family: $main-font;
    src: local($main-font), url(./Fonts/futura-bold.ttf) format('truetype');
}

@font-face {
    font-family: $secondary-font;
    src: local($secondary-font), url(./Fonts/futura-medium.ttf) format('truetype');
}

@font-face {
    font-family: $tertiary-font;
    src: local($tertiary-font), url(./Fonts/futura-light.ttf) format('truetype');
}

.app {
    background-color: $background-color;
    color: $main-text-color;
    font-family: $main-font;
    font-size: $main-font-size;

    width: 100vw;
    height: 100vh;

    overflow: hidden;
}

.center-child {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-btn {
    svg {
        transition: filter 250ms, transform 350ms;
    }

    max-width: $btnMaxSize;

    :hover {
        svg {
            filter: contrast(110%);
            transform: scale(1.08);
            cursor: pointer;
        }
    }
}

.min-h-100 {
    min-height: 100%;
}

.min-w-100 {
    min-width: 100%;
}