@use '../App' as *;

.temperature-cursor {

    $thickness: 10;

    width: calc(min(40vw, 50vh));

    svg {
        overflow: visible;
    }

    &.temperature-cursor-cold .thermometer-coloured-group {
        line {
            stroke: $color-cold;
        }

        circle {
            fill: $color-cold;
        }
    }

    &.temperature-cursor-comfort .thermometer-coloured-group {
        line {
            stroke: $color-comfort;
        }

        circle {
            fill: $color-comfort;
        }
    }

    &.temperature-cursor-hot .thermometer-coloured-group {
        line {
            stroke: $color-hot;
        }

        circle {
            fill: $color-hot;
        }
    }

    .thermometer-coloured-group {
        svg {
            overflow: visible;
        }

        line {
            stroke-width: $thickness;
            stroke-linecap: round;
        }
    }

    .thermometer-black-elements {

        text {
            font-size: 1.6em;
            text-anchor: middle;
        }
        
        line {
            stroke-width: $thickness - 3;
            stroke: black;
            stroke-linecap: round;
        }
    }
}