$timer-normal-color: rgb(255, 204, 110);
$timer-urgent-color: rgb(248, 172, 32);
$timer-almost-over-color: rgb(245, 91, 20);
$timer-over-color: rgb(250, 42, 27);

.timer {
    max-height: 100px;
    height: 13vh;

    .timer-label {
        transition: color 3000ms;
        color: $timer-normal-color;
    }

    .svg-icon {
        height: 100%;
    }
}

.timer.timer-urgent {
    .timer-label {
        color: $timer-urgent-color;
    }
}

.timer.timer-almost-over {
    .timer-label {
        color: $timer-almost-over-color;
    }
}

.timer.timer-over {
    .timer-label {
        color: $timer-over-color;
        transition: color 0s;
    }
}