$outline-color: #F7D97B;
$outline-width: 40;

$stroke-color: #123040;
$stroke-width: 6;

$from-angle: -24deg;
$to-angle: 18deg;
$animation-duration: 4s;

.swipe-demo {

    overflow: visible;

    svg {
        overflow: visible;
    }

    .outline-group path {
        stroke: $outline-color;
        stroke-width: $outline-width;
        stroke-miterlimit: 10;
        stroke-linecap: round;
        stroke-linejoin: round;

        fill: $outline-color;
    }

    .stroke-group path {
        stroke: $stroke-color;
        stroke-width: $stroke-width;
        stroke-miterlimit: 10;
        stroke-linecap: square;

        fill: none;
    }

    .hand-group {

        @keyframes swiperight {
            0% {
                transform: rotate($from-angle);
            }
            15% {
                transform: rotate($to-angle);
            }
            100% {
                transform: rotate($to-angle);
            }
        }

        animation: $animation-duration ease-out 0s infinite running swiperight;
        transform-origin: 37% 75%;
    }
}