.endscreen {

    .clap-icon-container {
        max-width: 9vw;
        width: 100%;
        max-height: 4.4em;

        .svg-icon {
            height: 100%;
        }
    }


    .endscreen-text {

        .header-text {
            font-size: 1.8em;
            line-height: 1.0;
            font-weight: bold;
        }

        .subtitle-text {
            font-size: 1.35em;
            line-height: 1.0;
            font-weight: bold;
        }
    }

    .endscreen-placeholder {
        height: 10vh;
    }
}