@use '../App' as *;

.popup {
    $sizeTransition: 400ms;
    $positionTransition: 400ms;

    width: 100vw;
    height: 100vh;

    color: $secondary-text-color;

    top: 0;
    left: 0;

    z-index: 1000;

    position: absolute;

    transition: transform $sizeTransition, left $positionTransition, top $positionTransition;

    .popup-background {
        background-color: rgba(0, 0, 0, 0.863);

        width: 100%;
        height: 100%;
    }

    img {
        border-radius: 0.95em;

        max-width: 90%;
        max-height: 90%;
    }
}

.popup-hidden {
    transform: scale(0%);
}
