$timer-margin: 0.65em;

.game-level {

    .timer {
        position: absolute;

        bottom: $timer-margin;
        right: $timer-margin;
    }
}