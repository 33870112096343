.explanations {
    overflow-y: auto;
    overflow-x: hidden;
}

.explanation-description {
    font-size: 1.25em;
}

.miniatures-main-container {

    .miniature-container-col {
        width: 48.5%;

        .explanation-miniature {
            padding: 3.5%;
            border-radius: 0.6em;
            box-shadow: 0px 0px 0.25em rgb(116, 116, 116), 0px 0px 0.32em rgb(100, 100, 100);

            :hover {
                cursor: pointer;
            }
            
            overflow: visible;

            background: white;
        
            img {
                object-fit: cover;

                border-radius: 0.5em;
                width: 100%;
                height: 100%;

                transition: filter 150ms;
            }
        
            transition: transform 250ms;
            &:hover {
                transform: scale(1.065);

                img {
                    filter: brightness(120%);
                }
            }
        }
    }


    $iconScaleFactor: 0.62;
    @media (max-width: 641.98px) {
        flex-direction: column;

        .miniature-container-col {
            width: 100%;
        }

        $imgWidth: 27vw;
        .explanation-miniature {
            width: $imgWidth;
            height: 0.75 * $imgWidth;
        }

        .miniature-placeholder {
            width: $imgWidth;
        }

        .miniature-col-icon {
            width: $iconScaleFactor * $imgWidth;
        }
    }

    @media (min-width: 642px) and (max-width: 767.98px) {
        flex-direction: column;

        .miniature-container-col {
            width: 100%;
        }

        $imgWidth: 21vw;
        .explanation-miniature {
            width: $imgWidth;
            height: 0.75 * $imgWidth;
        }

        .miniature-placeholder {
            width: $imgWidth;
        }

        .miniature-col-icon {
            width: $iconScaleFactor* $imgWidth;
        }
    }

    
    @media (min-width: 768px) and (max-width: 1199.98px) {

        flex-direction: row;

        $imgWidth: 20vw;
        .explanation-miniature {
            width: $imgWidth;
            height: 0.75 * $imgWidth;
        }

        .miniature-placeholder {
            width: $imgWidth;
        }

        .miniature-col-icon {
            width: $iconScaleFactor * $imgWidth;
        }
    }


    @media (min-width: 1200px) {

        flex-direction: row;

        $imgWidth: 14vw;
        .explanation-miniature {
            width: $imgWidth;
            height: 0.75 * $imgWidth;
        }

        .miniature-placeholder {
            width: $imgWidth;
        }

        .miniature-col-icon {
            width: $iconScaleFactor * $imgWidth;
        }
    }
}

.explanations-footer {

    .icon-btn {
        width: 18%;
    }

    .zoom-explanations {
        width: 18%;
    }

    .zoom-explanations-icon {
        width: 33%;
    }
}

.explanation-popup-content {
    max-height: 60%;
}