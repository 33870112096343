@use '../App' as *;

.swipable-cardstack {

    $cardHeight: 50vh;
    $cardWidth: calc(min(62vh, 50vw));

    $icon-size: calc(min(22vh, 18vw));
    
    $cardstackBgColor: $theme-white;
    $cardstackBorderColor: $cardstackBgColor;
    
    $cardstackTextColor: $theme-black;
    $cardFontSize: 1.1em;

    $cardOffsetLeft: 5px;
    $cardOffsetTop: 6px;
    
    $cardOffsetLeftHover: 6px;
    $cardOffsetTopHover: 7.5px;
    
    $cardTransition: 300ms;

    $imgBorderRadius: 0.95em;
    $imgMaxSize: 95%;
    
    height: $cardHeight;

    .cardstack {
    
        position: relative;
    
        width: $cardWidth;
        min-height: $cardHeight;
    
        @for $i from 1 through 10 {
            :nth-child(#{$i}) {
                left: ($i - 1) * $cardOffsetLeft;
                top: ($i - 1) * $cardOffsetTop;
            }
        }

        .card {   
            background-color: $cardstackBgColor;
            color: $cardstackTextColor;
            font-size: $cardFontSize;
            border: solid 1px $cardstackBorderColor;
            border-radius: 1.8em;
            box-shadow: 0px 0px 0.65em rgb(116, 116, 116), 0px 0px 0.75em rgb(0, 0, 0);

            img {
                border-radius: $imgBorderRadius;

                max-width: $imgMaxSize;
                max-height: $imgMaxSize;
            }
        }
    
        .card-dragging {
            background-color: darken($cardstackBgColor, 10%);
            border-color: darken($cardstackBorderColor, 10%);
        }

        .card-next > * {
            opacity: 0.9;
        }

        .card-clickable img {
            transition: transform 200ms;
        }

        .card-clickable img:hover {
            transform: scale(1.02);

            
            cursor: pointer;
        }

        color: $secondary-text-color;
    }

    .cardstack:hover {
    
        .card-animation {
            transition-delay: 150ms;
            transition: top $cardTransition, left $cardTransition;
        }

        @for $i from 1 through 10 {
            :nth-child(#{$i}) {
                left: ($i - 1) * $cardOffsetLeftHover;
                top: ($i - 1) * $cardOffsetTopHover;
            }
        }
    }

    .swipe-icon {

        .svg-icon {

            svg {
                transition: filter 150ms, transform 250ms;
            }
        }
        
    }

    .swipe-icon-clickable {
        svg:hover {
            filter: contrast(200%);
            transform: scale(1.08);
            cursor: pointer;
        }
    }
    
    .swipe-icon-disabled {
        svg {
            filter: grayscale(100%);
        }
    }

    .card, .card-dropzone {
        width: 100%;
        height: 100%;

        position: absolute;
    }

    .icon-dropzone {
        width: $icon-size;
    }

    .empty-placeholder {
        width: 0px;
        height: 0px;

        display: none;
    }

    .legend {
        font-size: 1.17em;
    }

    .legend-green {
        color: $theme-light-green;
    }

    .legend-yellow {
        color: $theme-yellow;
    }
}