$top-min-height: 3vh;
$top-main-height: 60vh;
$top-bottom-height: 20vh;

.tutorial-top {
    min-height: $top-min-height;
}


.tutorial-bottom {
    min-height: $top-bottom-height;

    width: calc(min(60vw, 80vh));
    .svg-icon {
        width: 4.2em;
    }
}

.tuto-step3-text {
    font-size: 1.1em;
}